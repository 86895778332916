.nav {
    width: 100%;
    height: 70px;
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 70px;
}

.rightNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NavLogo {
    height: 97%;
}

.desktop {
    display: flex !important;
    align-items: center;
}

.desktop span {
    font-weight: 500;
    margin: 0 25px 0 10px;
}

.navIcon {
    padding: 5px;
    width: 50px;
}

.navIcon:hover, .navIcon:active {
    background-color: rgba(128, 128, 128, 0.1);
    border-radius: 5px;
}

@media (max-width: 1124px) {
    .nav {
        padding: 0 20px;
    }

    .rightNav .login {
        display: none !important;
    }

    .menuIcon {
        color: #06abc9;
    }

    .sidebarIcon {
        display: block;
    }

    .desktop {
        display: none !important;
    }
}