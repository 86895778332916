.courseCard {
    background-color: white;
    padding: 20px 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.courseCard h2 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
}

.courseCard h3 {
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 17px;
}

.courseCard .classDetails {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 17px;
}

@media only screen and (max-width: 1300px) {
    .courseCard {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}


@media only screen and (max-width: 600px) {
    .courseCard {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .courseCard h2 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
    }

    .courseCard h3 {
        margin-bottom: 2px;
        font-size: 15px;
    }

}