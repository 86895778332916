.classesCont {
    width: calc(100% - 60px);
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    gap: 20px;
}

@media only screen and (max-width: 1400px) {
    .classesCont {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 20px;
        width: calc(100% - 40px)
    }
}

@media only screen and (max-width: 600px) {
    .classesCont {
        display: grid;
        grid-template-columns: 100%;
        gap: 20px;
        width: 100%
    }
}