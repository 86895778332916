.classDetailsCont {
    width: 100%;
    border-radius: 10px;
    background-color: #f0f1f3;
    padding: 40px;
    margin-bottom: 30px;
}


.studentsCont {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    gap: 20px;
    width: calc(100% - 80px)
}

.ClassDetailsBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.EditDeleteHeaderClassDetails {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0 15px;
}

@media only screen and (max-width: 1740px) {
    .studentsCont {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        gap: 20px;
        width: calc(100% - 60px)
    }
}

@media only screen and (max-width: 900px) {
    .studentsCont {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 20px;
        width: calc(100% - 40px)
    }

    .EditDeleteHeaderClassDetails {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 15px;
    }

    .ClassDetailsBox {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .classDetailsCont h2 {
        font-size: 20px !important;
    }

    .classDetailsCont h1 {
        font-size: 30px !important;
    }
}

@media only screen and (max-width: 600px) {
    .studentsCont {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 20px;
        width: calc(100% - 20px)
    }
}