.TeacherCont {
    width: 100%;
    /* display: flex;
    justify-content: center;
    margin-top: 60px; */
}

.LeftTeacher {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RightTeacher {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.TeacherPic {
    width: 300px;
}

.TeacherSocialIcon {
    font-size: 50px !important;
    margin: 10px;
    cursor: pointer;
}

.EditDeleteHeader {
    display: flex;
    justify-content: flex-end;
    margin: 0 15px;
}

@media (max-width: 1124px) {
    .TeacherCont {
        flex-direction: column;
    }

    .EditDeleteHeader {
        justify-content: center;
    }

    .RightTeacher {
        width: 100%;
    }

    .RightTeacher p {
        max-width: 100% !important;
    }

    .LeftTeacher {
        width: 100%;
        /* align-items: start; */
    }

    .TeacherPic {
        width: 180px;
    }

    .TeacherSocialIcon {
        font-size: 40px !important;
    }
}