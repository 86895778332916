.classDetailsCont {
    width: 100%;
    border-radius: 10px;
    background-color: #f0f1f3;
    padding: 40px;
    margin-bottom: 30px;
}

.reportHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 70px;
}

.reportIcon {
    font-size: 70px !important;
}


.attributeBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

.attributeBox h2 {
    width: 20%;
}

.attributeBox .MuiRating-root {
    display: flex;
    justify-content: space-between;
    width: 70%;
}

@media only screen and (max-width: 900px) {
    .attributeBox .MuiRating-root {
        display: flex;
        justify-content: space-between;
        width: 60%;
    }

    .reportIcon {
        font-size: 35px !important;
    }

    .attributeBox h2 {
        width: 20%;
        font-size: 13px !important;
    }

    .attrTitle {
        display: none;
    }
}