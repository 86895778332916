.FormWrapper {
    padding: 40px;
}

.LoginFormContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.LoginInput {
    margin: 10px 0 !important;
}


@media (max-width: 1124px) {
    .FormWrapper {
        padding: 20px;
    }
}