.dashBox {
    width: 100%;
    border-radius: 10px;
    padding: 30px;
    background-color: #f0f1f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.dashBoxWhite {
    width: calc(100% - 60px);
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.dashBoxBlock {
    border-radius: 10px;
    padding: 30px;
    width: fit-content;
    background-color: #f0f1f3;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    height: 500px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.dashBoxBlock h2 {
    margin-bottom: 10px;
    font-size: 20px;
}


.dashBox h1 {
    font-size: 30px;
}

.dashBox h2 {
    font-size: 18px;
}

.dashBox img {
    height: 150px;
}

.NumberBox {
    background-color: #f0f1f3;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.NumberBox:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.15);
}

.dashBoxBlock:hover, .dashBox:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.15);
}

.numbersIcon {
    background-color: white;
    border-radius: 50%;
    color: #06abc9;
    font-size: 70px !important;
    padding: 12px;
    margin-right: 15px;
}

.NumberBox h4 {
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.729);
}

.NumberBox h2 {
    font-size: 30px;
    font-weight: 900;
}

.chartsCont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.w75 {
    width: 64%;
}

.w25 {
    width: 34%;
}

.w50 {
    width: 49%;
}

.dashClassesHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

@media (max-width: 1124px) {
    .dashBox {
        padding: 10px;
    }

    .dashBoxBlock {
        padding: 10px;
        height: 400px
    }

    .chartsCont {
        flex-direction: column;
    }

    .w75, .w25, .w50 {
        width: 100%;
    }

}

@media (max-width: 700px) {

    .NumberBox {
        width: 100%;
    }

    .dashBoxBlock {
        height: 300px
    }

}

@media (max-width: 1300px) {
    .dashBoxWhite {
        width: calc(100% - 40px);
        grid-template-columns: 33.3% 33.3% 33.3%;
    }

}

@media (max-width: 900px) {
    .dashBoxWhite {
        width: calc(100% - 20px);
        grid-template-columns: 50% 50%;
    }

}

@media (max-width: 600px) {
    .dashBoxWhite {
        width: 100%;
        grid-template-columns: 100%;
    }

}