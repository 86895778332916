.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  box-shadow: 24px;
  padding: 32px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 95vh;
}

.small {
  max-width: 700px;
}

.smallExtra {
  max-width: 500px;
}

@media (max-width: 900px) {
  .modalStyle {
    width: 90%;
    padding: 15px;
  }
}