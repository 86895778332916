.Login {
    width: 100%;
    display: flex;
    height: 100vh;
}

.leftLogin {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Login .welcome {
    font-size: 35px;
    color: white;
    margin-bottom: 30px;
    text-align: center;
}

.rightLogin {
    background-color: var(--primary);
    flex-direction: column;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login .container {
    background-color: white;
    padding: 0px;
    width: 60%;
    border-radius: 20px;
}

.Login .Company_Logo {
    width: 400px;
    height: 400px;
}

@media (max-width: 1124px) {
    .Login {
        flex-direction: column;
        min-height: 100vh;
        height: fit-content;
    }

    .leftLogin {
        width: 100%;
    }

    .rightLogin {
        width: 100%;
        height: calc(100vh - 150px);
        justify-content: flex-start;
        padding-top: 70px;
    }

    .Login .Company_Logo {
        height: 150px;
        width: auto;
    }

    .Login .welcome {
        font-size: 20px;
        color: white;
        margin-bottom: 30px;
    }

    .Login .container {
        width: 90%;
    }
}