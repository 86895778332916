.CourseCont {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.LeftCourse {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RightCourse {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.CoursePic {
    width: 300px;
}

.EditDeleteHeader {
    display: flex;
    justify-content: flex-end;
    margin: 0 15px;
}

.detailsHeaderBoxProgram {
    width: 100%;
}

@media (max-width: 1124px) {
    .CourseCont {
        flex-direction: column;
    }

    .EditDeleteHeader {
        justify-content: center;
        flex-wrap: wrap;
    }

    .RightCourse {
        width: 100%;
    }

    .RightCourse p {
        max-width: 100%;
    }

    .LeftCourse {
        width: 100%;
        /* align-items: start; */
    }

    .CoursePic {
        width: 180px;
    }
}