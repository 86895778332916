.ClassTaskFormContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ClassTaskFormContainer .MuiStack-root {
  width: 100%;
}

.ClassTaskFormContainer .MuiAutocomplete-root {
  width: 100%;
}

.ClassTaskFormContainer .MuiTextField-root {
  width: 100%
}

.ClassTaskFormContainer .aboutLabel {
  margin: 0;
}

.darkImage:hover {
  filter: brightness(70%)
}

.ImageInputButton {
  background-color: #06abc9 !important;
}


@media (max-width: 1124px) {
  .ClassTaskFormContainer {
    flex-direction: column;
    align-items: initial;
  }

  .ImageInputButton {
    width: 100%;
    white-space: nowrap;
  }
}