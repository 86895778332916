.CourseFormContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;
}

.CourseFormContainer .LeftForm,
.CourseFormContainer .RightForm {
  width: 100% !important;
}

.CourseSmallFormWrapper .aboutLabel {
  margin: 0 !important;
}

.CourseSmallFormWrapper .area_text {
  margin: 10px 0 0 0 !important;
  width: 100% !important;
}

@media (max-width: 1124px) {
  .CourseFormContainer {
    flex-direction: column;
    align-items: initial;
  }
}