.SideListItem {
  width: 100%;
  padding: 25px 40px;
  cursor: pointer;
  margin-bottom: 5px;
}

.SideListItem li {
  list-style-type: none;
}

.SideListItem:hover,
.ActiveItem {
  width: 98%;
  background-color: #fff;
  margin-left: 2%;
  border-radius: 10px 0 0 10px;
}

.SideListItem:hover .ListTitle,
.ActiveItem .ListTitle {
  color: #068399;
}

.ListTitle {
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.ListTitle div {
  display: flex;
  align-items: center;
}

.ListIcon {
  margin-right: 10px;
  font-size: 22px !important;
}

.nested, .nested:hover {
  width: 90%;
  margin-left: 10%;
  padding: 20px 30px 20px 20px;
}

@media (max-width: 1124px) {

  .SideListItem:hover,
  .ActiveItem,
  .SideListItem {
    border-radius: 10px;
    width: 90%;
    margin: 3px auto;
  }

  .ListTitle {
    font-size: 14px;
  }
}