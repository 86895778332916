.SchoolCont {
    width: 100%;
}

.detailsHeaderBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.detailsHeaderBoxInside {
    /* width: 100%; */
    display: flex;
    align-items: center;
}

.greyBox {
    width: 100%;
    border-radius: 10px;
    padding: 15px 20px;
    background-color: #f0f1f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.greyBoxVer {
    width: 100%;
    border-radius: 10px;
    padding: 30px;
    background-color: #f0f1f3;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-bottom: 20px;
    flex-wrap: wrap;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    flex-direction: column;
}

.greyBox:hover, .greyBoxVer:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.15);
}

.greyBoxWhite {
    width: calc(100% - 60px);
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.greyNumberBox {
    background-color: #f0f1f3;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.greyNumberBox h4 {
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.729);
}

.greyNumberBox h2 {
    font-size: 30px;
    font-weight: 900;
}

.greyNumberBox:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.15);
}

.SchoolLogo {
    width: 150px;
    margin-right: 20px;
    border-radius: 50%;
}

.SchoolSocialIcon {
    font-size: 40px !important;
    margin: 15px 10px 0 0;
    cursor: pointer;
}

.EditDeleteHeader {
    display: flex;
    justify-content: flex-end;
    margin: 0 15px;
}

@media (max-width: 1124px) {
    .SchoolCont {
        flex-direction: column;
    }

    .EditDeleteHeader {
        justify-content: center;
    }

    .SchoolLogo {
        width: 180px;
    }

    .SchoolSocialIcon {
        font-size: 20px !important;
    }

    .greyBox {
        padding: 10px;
    }

    .greyBoxBlock {
        padding: 10px;
        height: 400px
    }

    .chartsCont {
        flex-direction: column;
    }
}

@media (max-width: 1300px) {
    .greyBoxWhite {
        width: calc(100% - 40px);
        grid-template-columns: 33.3% 33.3% 33.3%;
    }

}

@media (max-width: 900px) {
    .greyBoxWhite {
        width: calc(100% - 20px);
        grid-template-columns: 50% 50%;
    }

}

@media (max-width: 600px) {
    .greyBoxWhite {
        width: 100%;
        grid-template-columns: 100%;
    }


    .detailsHeaderBox {
        flex-direction: column-reverse;
        align-items: center;
    }


    .detailsHeaderBoxInside {
        flex-direction: column;
        align-items: center;
    }

    .SchoolLogo {
        margin: 10px 0 0 0;
    }

    .greyNumberBox {
        margin: 0;
    }

    .SchoolDetails {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SchoolDetails a {
        margin-bottom: 10px !important;
    }

    .SchoolSocialIcon {
        font-size: 30px !important;
        margin: 0;
    }

}