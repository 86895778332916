.StudentCont {
    width: 100%;
}

.LeftStudent {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RightStudent {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.StudentPic {
    width: 300px;
}

.StudentSocialIcon {
    font-size: 50px !important;
    margin: 10px;
    cursor: pointer;
}

.EditDeleteHeader {
    display: flex;
    justify-content: flex-end;
    margin: 0 15px;
}

@media (max-width: 1124px) {
    .StudentCont {
        flex-direction: column;
    }

    .EditDeleteHeader {
        justify-content: center;
    }

    .RightStudent {
        width: 100%;
    }

    .RightStudent p {
        max-width: 100% !important;
    }

    .LeftStudent {
        width: 100%;
        /* align-items: start; */
    }

    .StudentPic {
        width: 180px;
    }

    .StudentSocialIcon {
        font-size: 40px !important;
    }
}