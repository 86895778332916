.Home {
    width: 100%;
    height: 100vh;
}

.Home .container {
    width: 100%;
    display: flex;
}

.sidebar {
    width: 18%;
    position: static;
    min-height: calc(100vh - 70px);
    height: fit-content;    
    background-color: var(--primary);
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.sidebarIcon {
    display: none;
}

.icon {
    transition: opacity 0.4s ease-in-out,
}

.show {
    display: block;
    opacity: 1;
}

.hidden {
    display: none;
    opacity: 0;
}

.Home .main {
    width: 100%;
    max-height: calc(100vh - 70px);
    overflow-y: scroll;
}

.closeIcon {
    display: none;
}


@media (max-width: 1124px) {
    .sidebar {
        position: absolute;
        top: 0;
        width: 100vw !important;
        padding-top: 40px;
        min-height: 100vh;
        z-index: 1000;
        transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out, width 0.4s ease-in-out;
    }

    .sidebarOpen {
        opacity: 1;
        transform: translateX(0);
        width: 220px;
    }

    .sidebarClose {
        opacity: 0;
        transform: translateX(-100%);
        width: 0;
    }

    .closeIcon {
        /* position: absolute;
        top: 35px;
        right: 15px; */
        display: block;
        color: #fff !important;
        margin-bottom: 30px !important;
    }

    .login {
        display: block;
    }
}