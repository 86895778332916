.ClassSessionFormContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ClassSessionFormContainer .MuiStack-root {
  width: 100%;
}

.ClassSessionFormContainer .MuiAutocomplete-root {
  width: 100%;
}

.ClassSessionFormContainer .MuiTextField-root {
  width: 100%
}


@media (max-width: 1124px) {
  .ClassSessionFormContainer {
    flex-direction: column;
    align-items: initial;
  }
}