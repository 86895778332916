.Activate {
    width: 100%;
    display: flex;
    height: 100vh;
}

.leftActivate {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Activate h1 {
    font-size: 35px;
    color: white;
    margin-bottom: 10px;
}

.Activate h2 {
    font-size: 30px;
    color: white;
    margin-bottom: 30px;
}

.rightActivate {
    background-color: var(--primary);
    flex-direction: column;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Activate .container {
    background-color: white;
    padding: 0px;
    width: 60%;
    border-radius: 20px;
}

.Activate .Company_Logo {
    width: 400px;
    height: 400px;
}

@media (max-width: 1124px) {
    .Activate {
        flex-direction: column;
        min-height: 100vh;
        height: fit-content;
    }

    .leftActivate {
        width: 100%;
    }

    .rightActivate {
        width: 100%;
        height: calc(100vh - 150px);
        justify-content: flex-start;
        padding-top: 70px;
    }

    .Activate .Company_Logo {
        height: 150px;
        width: auto;
    }

    .Activate h1 {
        font-size: 20px;
        color: white;
        margin-bottom: 10px;
    }

    .Activate h2 {
        font-size: 15px;
        color: white;
        margin-bottom: 20px;
    }

    .Activate .container {
        width: 90%;
    }
}