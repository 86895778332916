.StudentFormContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.StudentSelect-placeholder .MuiSelect-select {
  color: rgba(0, 0, 0, 0.4) !important;
}

.noPadding * {
  padding: 0px !important;
}