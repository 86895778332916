.ReportCard {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ReportCard h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
}

.ReportCard h3 {
    margin-bottom: 2px;
}

.Buttons {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1300px) {
    .ReportCard {
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
    }

    .Buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ReportCard h2 {
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
    }
}