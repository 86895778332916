.ClassFormContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;
}

.ClassFormContainer .MuiStack-root {
  width: 100%;
}

.ClassFormContainer .MuiAutocomplete-root {
  width: 100%;
}

.MuiPopper-root {
  transition: none;
}

@media (max-width: 1124px) {
  .ClassFormContainer {
    flex-direction: column;
    align-items: initial;
  }
}