.SideBar {
    margin-top: 5px;
    /* padding: 0 0 75px 0; */
}

.SideBar ul {
    list-style-type: none;
}

.mobile {
    display: none !important;
    height: 0;
}

@media (max-width: 1124px) {
    .SideBar {
        width: 100vw;
    }

    .mobile {
        display: block;
        text-align: center;
        height: auto;
    }
}