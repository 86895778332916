.SchoolFormContainer {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.labelPhone {
    position: absolute;
    top: -7px;
    z-index: 1;
    left: 70px;
    font-size: 12px;
    background: white;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
}

.checkboxLabel {
    font-size: 15px;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    cursor: pointer;
}